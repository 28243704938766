import React, { Component } from 'react'

class MessagesHistory extends Component {

  constructor(){
    super()
    this.guestID = localStorage.getItem("id")
  }
  

  messagesEndRef = React.createRef()

  componentDidUpdate () {
  this.scrollToBottom()
  }
  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

    render() {
      return [].concat(this.props.items).reverse().map(item => (
        <div className={"message " + (((item || {}).user || {}).id===JSON.parse(this.guestID) ? "me" : "")} key={item.id}>
          {((item || {}).user || {}).id===JSON.parse(this.guestID)?(null):(<p style={{fontSize:"12px"}}>Triluxo Care-</p>)}
            <div className="message-body">
               { item.message}
              <div ref={this.messagesEndRef} />
            </div>  
          </div>
        ));
    }
  }

export default MessagesHistory
