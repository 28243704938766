import React, { Component } from 'react'
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { Modal } from 'react-bootstrap'
import "./auth.css"
import profile from "../../Images/profile-img.png"
import { baseUrl } from '../../config';

export class GuestSignin extends Component {

    constructor() {
        super()
        this.state = {
            phone: "",
            showModel: false,
            code: "91",
            otp: "",
            error: "",
        }
        this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
    }

    componentDidMount() {
        localStorage.clear();
        const elements = document.getElementsByClassName('WatsonAssistantChatHost');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    changePhoneHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleOTP = (e) => {
        this.setState({ otp: e.target.value })
    }

    isValidMobile(phoneno) {
        return /^[6-9]\d{9}$/.test(phoneno);
    }

    validateField(field, value) {
        if (value.length <= 0) {
            return <div className="alert alert-danger"><span className="text-capitalize">{field}</span> is required field.</div>;
        } else {
            if (field == 'code' && isNaN(this.state.code))
                return <div className="alert alert-danger">Invalid Phone Code.</div>;

            if (field == 'phone') {
                if (!this.isValidMobile(value))
                    return <div className="alert alert-danger">Invalid Phone Number.</div>;
            } else {
                return '';
            }
        }
    }

    handleBlur = (event) => {
        this.setState({ error: this.validateField(event.target.name, event.target.value) });
    }

    async handleOTPSubmit(e) {
        e.preventDefault()
        var currentComp = this
        var axios = require('axios');
        console.log(this.state.otp)
        var data = JSON.stringify({ "phoneCode": this.state.code, "phoneNo": this.state.phone, "otp": this.state.otp });

        var config = {
            method: 'post',
            url: `${baseUrl}/customer/verifyOTP`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config)
            .then(async function (response) {
                const { data, newUser, success } = response.data;
                if (success) {
                    localStorage.setItem('phoneCode', (`${currentComp.state.code}`))
                    localStorage.setItem('phoneNo', (`${currentComp.state.phone}`))
                }

                if (newUser) {
                    currentComp.props.history.push("/addProfile")
                }

                if (!newUser) {
                    localStorage.setItem('id', JSON.stringify(data._id))
                    localStorage.setItem('firstname', JSON.stringify(data.firstName))
                    localStorage.setItem('lastname', JSON.stringify(data.lastName))
                    localStorage.setItem('phoneCode', JSON.stringify(data.phoneCode))
                    localStorage.setItem('phoneNo', JSON.stringify(data.phoneNo))
                    currentComp.props.history.push("/profileView")
                }

            })
            .catch(function (err) {
                console.log(err);
                document.getElementById("errorOTP").innerHTML = "Something went wrong. Please try again !!"
            });
    }

    handleValidSubmit = (e) => {
        e.preventDefault()
        var currentComponent = this
        var phoneno = /^\d{10}$/;
        if (this.state.phone.match(phoneno)) {
            var axios = require('axios');
            var data = JSON.stringify({ "phoneCode": this.state.code, "phoneNo": this.state.phone });
            var config = {
                method: 'post',
                url: `${baseUrl}/customer/requestOtp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    document.getElementById("errorNo").innerHTML = ""
                    currentComponent.setState({ showModel: true }) //PopUp trigger
                })
                .catch(function (error) {
                    document.getElementById("errorNo").innerHTML = "Something went wrong. Please try again !!"
                    console.log(error);
                });
        } else {
            document.getElementById("errorNo").innerHTML = "Please provide a valid phone number."
        }
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3" />
                        <div className="col-lg-6">
                            <React.Fragment>
                                <div className="my-5 pt-sm-5">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col className="mx-5"><span className="font-triluxo">Triluxo</span></Col>
                                            <Col md={8} lg={6} xl={10}>
                                                <Card className="overflow-hidden" style={{ boxShadow: "0 0 10px #000000" }}>
                                                    <div className="bg-secondary rounded">
                                                        <Row>
                                                            <Col className="col-7">
                                                                <div className="text-white p-4">
                                                                    <h4 className="text-white">Guest Sign In</h4>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-5">
                                                                <img src={profile} alt="" className="img-fluid" />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <CardBody className="pt-3">
                                                        <div className="p-2">
                                                            <form className="form-horizontal">
                                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                                                <div className="row">
                                                                    <div className="col-1 mr-3 form-group">
                                                                        <input style={{ width: "60px" }} name="code" value={this.state.code} className="form-control" placeholder="code" type="text" required
                                                                            onBlur={this.handleBlur} onChange={this.changePhoneHandler} maxLength="2" minLength="2" />
                                                                    </div>
                                                                    <div className="col-9 form-group">
                                                                        <input name="phone" value={this.state.phone} className="form-control" placeholder="Enter phone number" type="tel" required
                                                                            onBlur={this.handleBlur} onChange={this.changePhoneHandler} maxLength="10" />
                                                                    </div>
                                                                </div>
                                                                <p id="errorNo" className="text-danger"></p>
                                                                {this.state.error ? this.state.error :
                                                                    <div className="mt-3 mb-4">
                                                                        <button className="btn btn-outline-primary btn-block waves-effect waves-light" type="submit" onClick={this.handleValidSubmit}>Request OTP</button>
                                                                    </div>}
                                                            </form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </React.Fragment>
                            <Modal show={this.state.showModel} dialogClassName="custom-dialog" >
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-6"><p className="text-dark text-left">An OTP is sent to your number {this.state.phone}</p></div>
                                        <div className="col-6 text-right">
                                            <button className="btn btn-light" onClick={() => this.setState({ showModel: false })}>&#x2715;</button>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <form className="form-horizontal">
                                            {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}

                                            <div className="form-group">
                                                <input name="otp" value={this.state.otp} className="form-control" placeholder="Enter OTP here..." type="text" required
                                                    onBlur={this.handleBlur} onChange={this.handleOTP} />
                                            </div>
                                            <p id="errorOTP" className="text-danger"></p>
                                            {this.state.error}
                                            <div className="mt-3 mb-4">
                                                <button className="btn btn-success btn-block" type="submit" onClick={this.handleOTPSubmit}>Verify OTP</button>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GuestSignin
