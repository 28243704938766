import React, { Component } from 'react'
import "./chat.scss"
import MessagesHistory from './MessagesHistory';
import firebase from "../../../Services/firebase" 
import moment from "moment"

class ChatApp extends Component {
    constructor(props) {
      super(props);
      this.state = { 
                     messages: [{message:'Please select the department above to chat with !!'}],
                     message: '',
                     dept: '',
                     activeItem: '',
                   };
                  
      this.deptList = localStorage.getItem("departments")
      this.guestID = localStorage.getItem("id")
      this.FirstName = localStorage.getItem("firstname")
      this.LastName = localStorage.getItem("lastname")
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    onListSelect=(item)=>{
      
      this.setState({activeItem: item.departmentName})

      var ref = firebase.database().ref(`/messages/${item.hotelId}/${item._id}/${JSON.parse(this.guestID)}`);
      const current = this
      ref.on("value", function(snapshot) {
        console.log(item.departmentName)
        console.log(current.state.activeItem)
        console.log(snapshot.val());
        if(item.departmentName==current.state.activeItem){
          if(snapshot.val()!=null)
            current.setState({messages: Object.values(snapshot.val())})
          else
            current.setState({messages: {id:'1',message:'Please put your query here.'}})
      }}, function (error) {
        console.log("Error: " + error.code);
      });
      this.setState({dept: item})
    }

  
    handleChange(e) {
        this.setState({ message: e.target.value });
      }
    
      handleSubmit(e) {
        e.preventDefault();
        if (!this.state.message.length) {
          return;
        }
        var item = this.state.dept
        const newReference = firebase.database()
        .ref(`/messages/${item.hotelId}/${item._id}/${JSON.parse(this.guestID)}`)
        .push();

      const msgObj = {
        id: newReference.key,
        message: this.state.message,
        created: moment().format('YYYY-MM-DD HH:mm:ss'),
        user: {
          id: JSON.parse(this.guestID),
          name: `${JSON.parse(this.FirstName)} ${JSON.parse(this.LastName)}`,
          avatar: "avatar",  //TO-DO
        },
      };
      // send to DB
      newReference.set(msgObj).then(
        //() => console.log('set data.')
      );

        this.setState({
          messages: this.state.messages.concat(msgObj),
          message: ''
        }); 

      }


    render() {
      return (
        <div className="app row mb-5">
          <div className="contact-list col-sm-4 text-center">
            <ul>
              {JSON.parse(localStorage.getItem('departments')).map(item => (
                <div key={item._id} onClick={()=>this.onListSelect(item)}>
                <li className={item.departmentName==this.state.activeItem ? "active" : ""} >
                  {item.departmentName}
                </li></div>
              ))}
            </ul>
          </div>
          <div className="col-sm-8">
          <div className="rounded bg-secondary text-white text-center mt-2"><h6>{this.state.activeItem} Chat here...</h6></div>
          <div className="messages" style={{height:"500px", overflowY:"auto"}}>
            <div className="messages-history">
              <MessagesHistory key={this.state.activeItem} items={this.state.messages} />  
            </div>
            {this.state.dept?
              (<form className="messages-inputs" onSubmit={this.handleSubmit}>
              <input type="text" placeholder="Send a message" onChange={this.handleChange} value={this.state.message}/>
                <button type="submit"><i className="material-icons">&#xe163;</i></button>
            </form>):(null)}
          </div>
          </div>
        </div>
      );
    }
  }
  

export default ChatApp
