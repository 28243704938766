import React, { Component } from 'react'
import UserDashboard from './UserDashboard'
import Logo from "../../Images/logo-ramada.png"
import Hotel from "../../Images/hotel.jpg"
import { baseUrl } from '../../config'

export class UserHome extends Component {

    constructor() {
        super()
    }

    async componentDidMount() {

        // var axios = require('axios');
        // var data = JSON.stringify({ "email": JSON.parse(this.email) });

        // var config = {
        //     method: 'post',
        //     url: `${baseUrl}/customer/me`,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: data
        // };

        // await axios(config)
        //     .then(async function (response) {
        //         await console.log(JSON.stringify(response.data));;
        //         localStorage.setItem('id', JSON.stringify(response.data.data._id))
        //         localStorage.setItem('firstname', JSON.stringify(response.data.data.firstName))
        //         localStorage.setItem('lastname', JSON.stringify(response.data.data.lastName))
        //         localStorage.setItem('phoneCode', JSON.stringify(response.data.data.phoneCode))
        //         localStorage.setItem('phoneNo', JSON.stringify(response.data.data.phoneNo))
        //         localStorage.setItem('email', JSON.stringify(response.data.data.email))
        //         localStorage.setItem('departments', JSON.stringify(response.data.data.hotelId.department))
        //         if (JSON.parse(localStorage.getItem('departments')).length == 0) {
        //             this.props.history.push("/profileView")
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        // if (localStorage.getItem("hotelname") === null) {
        //     this.props.history.push("/guest-signin")
        // }
        // else if (JSON.parse(localStorage.getItem('departments')).length == 0) {
        //     this.props.history.push("/profileView")
        // }
    }

    render() {
        return (
            <div>
                {/* {JSON.parse(this.hotelName) == null ? null : <UserDashboard />} */}
                <div className="row ml-3 mr-1" style={{ position: "relative", zIndex: 1, paddingTop: "80px" }}>
                    <div className="col-md-3" />
                    <div className="col-md-6 card py-3 ml-5 my-3" style={{ boxShadow: " 1px 1px 4px 4px #888888" }}>
                        <div className="text-center">
                            <img className="img-fluid" src={Logo} alt="logo" width="170px" />
                        </div>
                        <div className="text-center">
                            <h3>Hello world!</h3>
                        </div>
                    </div>
                </div>
                <div className="row ml-3 mr-1">
                    <div className="col-md-3" />
                    <div className="col-md-6 card py-1 px-1 ml-5">
                        <img className="img-fluid" src={Hotel} alt="logo" />
                    </div>
                </div>
            </div>
        )
    }
}

export default UserHome
