import firebase from "firebase"

var firebaseConfig = {
    apiKey: "AIzaSyAXF-fPQ_QyO4X7V1scKxyrdfGqU7oul-c",
    authDomain: "triluxogcp.firebaseapp.com",
    databaseURL: "https://triluxogcp.firebaseio.com",
    projectId: "triluxogcp",
    storageBucket: "triluxogcp.appspot.com",
    messagingSenderId: "836086766276",
    appId: "1:836086766276:web:27435daaeb5710bb368791",
    measurementId: "G-G71B8R0HNQ"
};
 // Initialize Firebase
 firebase.initializeApp(firebaseConfig);
 firebase.analytics();

 export default firebase;