import React, { Component } from 'react'
import UserDashboard from './UserDashboard'
import guest from "../../Images/guest.png"

export class GuestProfile extends Component {

    constructor(){
        super()
        
        this.currentUserFirstName = localStorage.getItem('firstname')
        this.currentUserLastName = localStorage.getItem('lastname')
        this.currentUserEmail = localStorage.getItem('email')
        this.currentUserPhonecode = localStorage.getItem('phoneCode')
        this.currentUserPhoneno = localStorage.getItem('phoneNo')
        this.departments = localStorage.getItem('departments')
    }

    componentDidMount(){
        if (localStorage.getItem("hotelname") === null) {
          this.props.history.push("/guest-signin")
        }
      }


    logout = () => {
        this.props.history.push("/")
        localStorage.clear()
    }

    render() {
        return (
            <div >
            {this.departments != null ?<UserDashboard/>:null}
                <div className="row ml-3 mr-1 " 
                style={{position:"relative", zIndex:1, paddingTop:"80px" }}>
                <div className="col-md-3"/>
                    <div className="col-md-6 card px-3 py-4 ml-5 mt-3" style={{boxShadow: " 1px 1px 4px 4px #888888"}}>
                        <div className="rounded bg-secondary text-white text-center"><h2>Profile</h2></div>
                            <div className="card-body text-center">
                                <img className="img-fluid" src={guest} alt="guest" width="150px"/>
                                <h4 className="mt-5">{JSON.parse(this.currentUserFirstName)} {JSON.parse(this.currentUserLastName)}</h4>
                                <p className="mt-3">
                                    <span className="text-white p-2 rounded" 
                                    style={{backgroundColor:"rgb(255, 127, 79)" }}>
                                    {JSON.parse(this.currentUserEmail)}  </span>
                                </p>
                                <h5 className="mt-3">+{JSON.parse(this.currentUserPhonecode)}-{JSON.parse(this.currentUserPhoneno)}</h5>
                                <button className="btn btn-outline-danger mt-5" onClick={this.logout}>Sign out</button>
                            </div>
                        </div>
                </div>  
                <div className="col-md-3"/>
            </div>
        )
    }
}

export default GuestProfile
