import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
import AddProfile from './Components/Authentication/AddProfile';
import GuestSignin from './Components/Authentication/GuestSignin';
import Departments from './Components/UserDashboard/Departments';
import GuestProfile from './Components/UserDashboard/GuestProfile';
import ProfileView from './Components/UserDashboard/ProfileView';
import UserHome from './Components/UserDashboard/UserHome';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/"><Redirect to="/guest-signin" /></Route>
          {/*<Route path="/hotel-login" component={HotelLogin} />*/}
          <Route path="/guest-signin" component={GuestSignin} />
          {/*<Route path="/hotel-dashboard" component={HotelDashboard} />
          <Route path="/user-dashboard" component={UserDashboard} />*/}
          
          <Route path="/addProfile" component={AddProfile}/>
          <Route path="/profileView" component={ProfileView}/>
          <Route path="/home" component={UserHome} />
          <Route path="/guest-profile" component={GuestProfile} />
          <Route path="/departments" component={Departments} />

        </Switch>
      </Router>
    </div>
  );
}

export default App;
