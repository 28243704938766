import React, { Component } from 'react'
import './Common.scss'
import logo from "../../Images/logo.PNG"
import home from "../../Images/home.png"
import department from "../../Images/department.png"
import profile from "../../Images/profile.png"
import {NavLink} from "react-router-dom"

const navLinks = [
  { url: '/home', name: 'Home', icon: home },
  { url: '/departments', name: 'Departments', icon: department },
  { url: '/guest-profile', name: 'Profile', icon: profile },
];

export class Menu extends Component {  
    constructor(){
      super();
      this.state = {
        style:"menu",
        menuStatus:"open",
      };

      this.handleClick = this.handleClick.bind(this);
    };

    

    handleClick() {
      switch(this.state.menuStatus)
      {
        case "open":
          this.setState({
            menuStatus:"close",
            style:"menu active"
          });
          break;
        case "close":
          this.setState({
            menuStatus:"open",
            style:"menu"
          });
          break;
      }        
    }
  
    
  

    render() {
      return (      
        <div style={{position:"fixed", zIndex:2, width: "100%"}}>
          <div className="bg-light p-3" >
            <img src={logo} alt="logo" height="50px" width=""/>
            <button className="btn btn-outline-primary mx-4" onClick={this.handleClick}>::::</button>
          </div>
          
          <div>
            <div className={this.state.style}>               
              <ul>
                {navLinks.map(({ url, name }) => (
                <li key={url}>
                  <NavLink id="link" activeClassName="activeRoute" to={url} onClick={this.handleClick}>{name}</NavLink>
                </li>
              ))}
              </ul>
            </div>
            <div className="shortcuts active">
              <ul>
                {navLinks.map(({ url, icon }) => (
                <li key={url}>
                  <NavLink id="link" activeClassName="activeRoute" to={url}>
                    <img src={icon} alt="icon" height="19px" width="19px"/>
                  </NavLink>
                </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }

export default Menu
