import React, { Component } from 'react'
import Menu from '../Common/Menu';

export class UserDashboard extends Component {

    componentDidMount() {

        window.watsonAssistantChatOptions = {
            integrationID: "b45935f0-162e-43c8-becf-4a51ae1a854e", // The ID of this integration.
            region: "us-south", // The region your integration is hosted in.
            serviceInstanceID: "89613bfd-2fd5-400a-a35c-dd0d49e9f84f", // The ID of your service instance.
            onLoad: function (instance) { instance.render(); }
        };
        setTimeout(function () {
            const t = document.createElement('script');
            t.src = "https://web-chat.global.assistant.watson.appdomain.cloud/loadWatsonAssistantChat.js";
            document.head.appendChild(t);
        });
    }
    componentWillUnmount(){
        const elements=document.getElementsByClassName('WatsonAssistantChatHost');
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }


    render() {
        return (
           <Menu/>
        )
    }
}

export default UserDashboard
